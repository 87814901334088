import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import captureUnitId from '../utils/capture-unit-id';
import EnsureAuthenticated from '../containers/EnsureAuthenticated';
import UnitAddress from '../components/UnitAddress';
import ScheduleShowing from '../containers/ScheduleShowing';
import SelfShowingLayout from '../layouts/SelfShowing';
import EnsureTrusted from '../../src/containers/EnsureTrusted';

/* eslint-disable */
export default withRouter(
  class Route extends Component {
    render() {
      const unitId = captureUnitId(this.props);
      if (!unitId) {
        this.props.history.push('/homes');
      }

      return (
        <UnitAddress unitId={unitId}>
          {({ unit, unitAddress, market, error }) =>
            (!!unitAddress || error) && (
              <EnsureAuthenticated
                pathName="/login"
                redirectTo="schedule a showing"
                redirectFor={unitAddress}
                verificationRequired={['email', 'phone', 'first_name', 'last_name']}
              >
                <EnsureTrusted unit={unit}>
                  <SelfShowingLayout px={[20, 30, 60]} py={40}>
                    <ScheduleShowing
                      match={this.props.match}
                      location={this.props.location}
                      history={this.props.history}
                      unitAddress={unitAddress}
                      unitId={unitId}
                      market={market}
                    />
                  </SelfShowingLayout>
                </EnsureTrusted>
              </EnsureAuthenticated>
            )
          }
        </UnitAddress>
      );
    }
  },
);

/* eslint-disable complexity */
export const filterNotifications = (notificationList, _, applicationName) => {
  if (!Array.isArray(notificationList)) {
    return undefined;
  }

  const activeNotifications = notificationList
    .filter((notification) => {
      if (notification.props) {
        try {
          const cookie = getNotificationCookie(notification?.props?.id);

          if (isCookie(cookie)) {
            if (isExpiredNotification(cookie)) {
              window.localStorage.removeItem(notification.props.id);
              return getIsActiveNotification(cookie, applicationName);
            }
            return false;
          }

          return getIsActiveNotification(notification.props, applicationName);
        } catch (error) {
          return undefined;
        }
      }
    })
    .sort(sortNotifications);

  if (activeNotifications.length > 0) {
    return activeNotifications[0].props;
  }
  return undefined;
};

export const getIsActiveNotification = (notification, applicationName) => {
  if (!notification || notification.legacyPage) return false;

  if (notification.listOfApplications && notification.listOfApplications.length > 0) {
    return notification.listOfApplications.some((app) => {
      return app.props.name === applicationName;
    });
  }
  return true;
};

export const isCookie = (cookie) => {
  if (cookie === null || typeof cookie === 'undefined') {
    return false;
  }

  return Object.keys(cookie).length > 0 && cookie.constructor === Object;
};

export const isExpiredNotification = (notification) => {
  if (notification?.expires === undefined) return false;

  return Date.now() > notification.expires;
};

export const getNotificationCookie = (id) => {
  try {
    const cookieNotification = window.localStorage.getItem(id);

    if (cookieNotification && Object.keys(cookieNotification).length) {
      return JSON.parse(cookieNotification);
    }
  } catch (e) {
    return {};
  }
  return {};
};

export const sortNotifications = (a, b) => {
  const aHasList = !!a.props?.listOfApplications;
  const bHasList = !!b.props?.listOfApplications;
  if (bHasList) {
    return 1;
  }
  if (aHasList) {
    return -1;
  }
  return 0;
};

import React from 'react';

const Info = () => (
  <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833344 9.99999C0.833344 4.93738 4.9374 0.833328 10 0.833328C15.0626 0.833328 19.1667 4.93738 19.1667 9.99999C19.1667 15.0626 15.0626 19.1667 10 19.1667C4.9374 19.1667 0.833344 15.0626 0.833344 9.99999ZM10 2.49999C5.85787 2.49999 2.50001 5.85786 2.50001 9.99999C2.50001 14.1421 5.85787 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 9.99999C17.5 5.85786 14.1421 2.49999 10 2.49999Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833344 9.99999C0.833344 4.93738 4.9374 0.833328 10 0.833328C15.0626 0.833328 19.1667 4.93738 19.1667 9.99999C19.1667 15.0626 15.0626 19.1667 10 19.1667C4.9374 19.1667 0.833344 15.0626 0.833344 9.99999ZM10 2.49999C5.85787 2.49999 2.50001 5.85786 2.50001 9.99999C2.50001 14.1421 5.85787 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 9.99999C17.5 5.85786 14.1421 2.49999 10 2.49999Z"
      fill="currentColor"
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 9.16667C10.4602 9.16667 10.8333 9.53977 10.8333 10V13.3333C10.8333 13.7936 10.4602 14.1667 10 14.1667C9.53977 14.1667 9.16667 13.7936 9.16667 13.3333V10C9.16667 9.53977 9.53977 9.16667 10 9.16667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 9.16667C10.4602 9.16667 10.8333 9.53977 10.8333 10V13.3333C10.8333 13.7936 10.4602 14.1667 10 14.1667C9.53977 14.1667 9.16667 13.7936 9.16667 13.3333V10C9.16667 9.53977 9.53977 9.16667 10 9.16667Z"
      fill="currentColor"
      fillOpacity="0.6"
    />
    <path
      d="M10.8333 6.66666C10.8333 7.1269 10.4602 7.49999 10 7.49999C9.53977 7.49999 9.16667 7.1269 9.16667 6.66666C9.16667 6.20642 9.53977 5.83333 10 5.83333C10.4602 5.83333 10.8333 6.20642 10.8333 6.66666Z"
      fill="currentColor"
    />
    <path
      d="M10.8333 6.66666C10.8333 7.1269 10.4602 7.49999 10 7.49999C9.53977 7.49999 9.16667 7.1269 9.16667 6.66666C9.16667 6.20642 9.53977 5.83333 10 5.83333C10.4602 5.83333 10.8333 6.20642 10.8333 6.66666Z"
      fill="currentColor"
      fillOpacity="0.6"
    />
  </svg>
);

export default Info;

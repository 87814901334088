/* eslint-disable */
import React from 'react';
import { parseURL } from '../../utils/parse-ih-url';

export const RichTextMessage = ({ message, environment }) => {
  const paragraphContentAsText = (node) => {
    if (node.type === 'text') return node;
    return null;
  };

  const isTextBold = (props) => {
    return props.marks?.find((mark) => mark.type === 'bold') !== undefined;
  };

  const isHyperlinkBold = (content) => {
    return paragraphContentAsText(content)?.props.marks?.find(({ type }) => type === 'bold') !== undefined;
  };

  if (!message?.content) return null;

  return (
    <>
      {message.content.map((block, blockIndex) => (
        <p key={blockIndex}>
          {block?.content?.map((item, itemIndex) => {
            if (item.type === 'text' && item.props?.value) {
              return (
                <span key={itemIndex} className={isTextBold(item.props) ? 'font-bold' : ''}>
                  {item.props.value}
                </span>
              );
            } else if (
              item.type === 'hyperlink' &&
              item.data?.uri &&
              item.content?.[0] &&
              paragraphContentAsText(item.content[0])
            ) {
              return (
                <a
                  key={itemIndex}
                  className={`link ${isHyperlinkBold(item.content[0]) ? 'font-bold' : ''}`}
                  href={parseURL(item.data.uri, environment)}
                  target="_self"
                  tabIndex={-1}
                >
                  {paragraphContentAsText(item.content[0])?.props?.value ?? ''}
                </a>
              );
            }
            return null;
          })}
        </p>
      ))}
    </>
  );
};

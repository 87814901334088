import React, { useState } from 'react';
import moment from 'moment-timezone';
import { find } from 'lodash';

import { Button } from '@invitation-homes/styled-ui/lib/components/Button/Button';
import Grid from '@material-ui/core/Grid';
import { Label, Value, StyledGrid } from '../components/CardCommon';

// components

export default function Times({ available, timezone, selectedDay, selectedTime, onSelect }) {
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={3} xs={12} justify="center" id="times_block">
        {find(available, (showing) => {
          const showingDate = moment(showing.date).tz(timezone).startOf('day');
          const selectedDate = moment(selectedDay).tz(timezone).startOf('day');
          return showingDate.isSame(selectedDate);
        }).times.map((time, index) => {
          const hours = `${moment(time.time).tz(timezone).format('h')}:00 ${moment(time.time)
            .tz(timezone)
            .format('A')} - ${moment(time.time).tz(timezone).add(1, 'h').format('h')}:00 ${moment(time.time)
            .tz(timezone)
            .add(1, 'h')
            .format('A')}`;
          const active =
            index === activeIndex ||
            (selectedTime &&
              selectedTime.start.tz(timezone).format('LLL') === moment(time.time).tz(timezone).format('LLL'));
          const onClick = () => {
            setActiveIndex(index);
            onSelect({
              start: moment(time.time).tz(timezone),
              end: moment(time.time).tz(timezone).add(1, 'h'),
            });
          };
          return (
            <StyledGrid key={hours}>
              <Button
                fullWidth
                color={active ? 'primary' : 'white'}
                variant="contained"
                p={0}
                onClick={onClick}
                active={active}
                {...(index === 0 ? { id: 'first_time' } : { id: `time_${index + 1}` })}
                style={{ height: '100%' }}
              >
                <Value
                  padding="10px 0"
                  margin="0"
                  onClick={onClick}
                  style={{ color: active && 'white', fontWeight: 'bold' }}
                >
                  <Label color={active ? 'white' : 'default'}>Duration</Label>
                  {hours}
                </Value>
              </Button>
            </StyledGrid>
          );
        })}
      </Grid>
    </div>
  );
}

import React from 'react';

const TriangleAlert = () => (
  <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.86251 3.61783C8.31572 2.87068 9.12615 2.4144 10 2.4144C10.8739 2.4144 11.6843 2.87068 12.1375 3.61783L12.1399 3.62179L19.1982 15.4051L19.205 15.4166C19.6492 16.1859 19.6518 17.1331 19.212 17.9048C18.7721 18.6765 17.9557 19.1569 17.0675 19.1666L17.0583 19.1667L2.93251 19.1667C2.04428 19.1569 1.22793 18.6765 0.788048 17.9048C0.348165 17.1331 0.350817 16.1859 0.795015 15.4166L0.801784 15.4051L7.86251 3.61783ZM9.28852 4.48055L2.23558 16.2549C2.09026 16.5104 2.0903 16.8238 2.23601 17.0795C2.38201 17.3356 2.65241 17.4954 2.947 17.5H17.053C17.3476 17.4954 17.618 17.3356 17.764 17.0795C17.9097 16.8238 17.9098 16.5105 17.7645 16.2549L10.7125 4.48221C10.7122 4.48165 10.7118 4.4811 10.7115 4.48055C10.5602 4.23248 10.2906 4.08106 10 4.08106C9.70937 4.08106 9.43978 4.23248 9.28852 4.48055Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.86251 3.61783C8.31572 2.87068 9.12615 2.4144 10 2.4144C10.8739 2.4144 11.6843 2.87068 12.1375 3.61783L12.1399 3.62179L19.1982 15.4051L19.205 15.4166C19.6492 16.1859 19.6518 17.1331 19.212 17.9048C18.7721 18.6765 17.9557 19.1569 17.0675 19.1666L17.0583 19.1667L2.93251 19.1667C2.04428 19.1569 1.22793 18.6765 0.788048 17.9048C0.348165 17.1331 0.350817 16.1859 0.795015 15.4166L0.801784 15.4051L7.86251 3.61783ZM9.28852 4.48055L2.23558 16.2549C2.09026 16.5104 2.0903 16.8238 2.23601 17.0795C2.38201 17.3356 2.65241 17.4954 2.947 17.5H17.053C17.3476 17.4954 17.618 17.3356 17.764 17.0795C17.9097 16.8238 17.9098 16.5105 17.7645 16.2549L10.7125 4.48221C10.7122 4.48165 10.7118 4.4811 10.7115 4.48055C10.5602 4.23248 10.2906 4.08106 10 4.08106C9.70937 4.08106 9.43978 4.23248 9.28852 4.48055Z"
      fill="currentColor"
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7.5C10.4602 7.5 10.8333 7.8731 10.8333 8.33333V11.6667C10.8333 12.1269 10.4602 12.5 10 12.5C9.53977 12.5 9.16667 12.1269 9.16667 11.6667V8.33333C9.16667 7.8731 9.53977 7.5 10 7.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7.5C10.4602 7.5 10.8333 7.8731 10.8333 8.33333V11.6667C10.8333 12.1269 10.4602 12.5 10 12.5C9.53977 12.5 9.16667 12.1269 9.16667 11.6667V8.33333C9.16667 7.8731 9.53977 7.5 10 7.5Z"
      fill="currentColor"
      fillOpacity="0.6"
    />
    <path
      d="M10.8333 15C10.8333 15.4602 10.4602 15.8333 10 15.8333C9.53977 15.8333 9.16667 15.4602 9.16667 15C9.16667 14.5398 9.53977 14.1667 10 14.1667C10.4602 14.1667 10.8333 14.5398 10.8333 15Z"
      fill="currentColor"
    />
    <path
      d="M10.8333 15C10.8333 15.4602 10.4602 15.8333 10 15.8333C9.53977 15.8333 9.16667 15.4602 9.16667 15C9.16667 14.5398 9.53977 14.1667 10 14.1667C10.4602 14.1667 10.8333 14.5398 10.8333 15Z"
      fill="currentColor"
      fillOpacity="0.6"
    />
  </svg>
);

export default TriangleAlert;

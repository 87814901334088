button {
  background: transparent;
  border: none;
  color: currentColor;
  padding: 0;
}
.notification__dismiss {
  margin-right: 16px;
}
.notification__wrapper {
  position: relative;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
}
.notification-bar__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 48px;
  padding-left: 16px;
  transition: background-color 0.25s ease-out;
  z-index: var(--notification-z-index, 10);
}
.icon,
.toggle-icon,
button {
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.extended .notification-bar {
  position: relative;
  transition: box-shadow 0.25s ease-out;
  transition: background-color 0.25s ease-out;
}
.extended .notification-bar:hover {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
}
.extended .notification-bar,
.cta .heading,
button {
  cursor: pointer;
}
.heading {
  display: flex;
  align-items: center;
  gap: 8px;
}
h5 {
  font-size: var(--text-sub);
  font-weight: 700;
  margin: 0;
  width: max-content;
}
.dropdown__content {
  width: 100%;
  padding: 0 16px 0 16px;
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-out, padding 0.3s ease-out;
  box-sizing: border-box;
  z-index: var(--notification-dropdown-z-index, 9);
}
.open .dropdown__content {
  padding-top: 16px;
  padding-bottom: 16px;
  grid-template-rows: 1fr;
}
.dropdown__content div {
  margin: unset;
  line-height: 22px;
  transition: margin 0.3s ease-out;
  font-size: var(--text-body-2);
  overflow: hidden;
  text-align: left;
}
.dropdown__content a,
.cta__message a {
  color: currentColor;
  text-decoration: underline;
  cursor: pointer;
}
.open .dropdown__content p {
  margin-top: 4px;
  margin-bottom: 4px;
}
.d-none,
.cta__message {
  display: none;
}
.extended .toggle-icon {
  display: block;
  transition: transform 0.5s;
}
.open .toggle-icon {
  transform: rotate(180deg);
}
.cta .cta__message {
  font-size: var(--text-body);
  color: var(--color-white);
  margin: 0;
  align-items: center;
  text-decoration: none;
}
.link {
  font-weight: 700;
  width: max-content;
}
.info {
  background: var(--color-info-dark);
  color: var(--color-white);
}
.info.extended .notification-bar:hover,
.info .dropdown__content {
  background: var(--color-info-hover);
}
.success {
  background: var(--color-success-dark);
  color: var(--color-white);
}
.success.extended .notification-bar:hover,
.success .dropdown__content {
  background: var(--color-success-hover);
}
.error {
  background: var(--color-error-dark);
  color: var(--color-white);
}
.error.extended .notification-bar:hover,
.error .dropdown__content {
  background: var(--color-error-hover);
}
.warning {
  background: var(--color-warning-light);
  color: var(--color-secondary);
}
.warning.extended .notification-bar:hover,
.warning .dropdown__content {
  background: var(--color-warning-hover);
}
.general {
  background: var(--color-info-light-1);
  color: var(--color-secondary-dark);
}
.general.extended .notification-bar:hover,
.general .dropdown__content {
  background: var(--color-banner-blue-accent);
}
.special {
  background: var(--color-success-light-1);
  color: var(--color-secondary-dark);
}
.special.extended .notification-bar:hover,
.special .dropdown__content {
  background: var(--color-banner-green-accent);
}

@media (min-width: 768px) {
  .notification-bar__content,
  .dropdown__content {
    justify-content: center;
  }
  .dropdown__content p {
    text-align: center;
  }
  .cta__message p {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .cta .cta__message {
    display: flex;
  }
  .heading p {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 8px 0 0;
    text-overflow: ellipsis;
    line-height: normal;
  }
  .notification__dismiss {
    position: absolute;
    right: 16px;
    margin: 0;
  }
  .notification-bar__content {
    padding: 0;
    max-width: var(--tablet-container-max-width, 100%);
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .heading p {
    max-width: 560px;
  }
  .notification-bar__content {
    justify-content: center;
    max-width: var(--desktop-container-max-width, 100%);
  }
}

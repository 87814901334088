import React from 'react';

const Check = () => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.663 4.77344C11.0222 3.15121 6.74811 4.11689 4.15844 7.1468C1.56877 10.1767 1.28046 14.5491 3.44985 17.8928C5.61925 21.2365 9.72956 22.7551 13.5518 21.6249C17.3741 20.4948 19.9977 16.9853 20 12.9994V12.07C20 11.5177 20.4477 11.07 21 11.07C21.5523 11.07 22 11.5177 22 12.07V13C21.9972 17.8716 18.7905 22.1616 14.1189 23.5429C9.44725 24.9241 4.42353 23.0681 1.77204 18.9814C-0.879439 14.8946 -0.527056 9.55058 2.6381 5.84736C5.80325 2.14414 11.0272 0.963857 15.477 2.94658C15.9815 3.17136 16.2082 3.76253 15.9834 4.26701C15.7586 4.77148 15.1675 4.99822 14.663 4.77344Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7071 3.29289C23.0976 3.68342 23.0976 4.31658 22.7071 4.70711L11.7071 15.7071C11.3166 16.0976 10.6834 16.0976 10.2929 15.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929L11 13.5858L21.2929 3.29289C21.6834 2.90237 22.3166 2.90237 22.7071 3.29289Z"
      fill="currentColor"
    />
  </svg>
);

export default Check;

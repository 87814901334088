/* eslint-disable */
import React, { useState } from 'react';
import ChevronDown from '../icons/ChevronDown';
import Info from '../icons/Info';
import TriangleAlert from '../icons/TriangleAlert';
import Check from '../icons/Check';
import X from '../icons/x';
import { RichTextMessage } from './RichTextMessage';
import styles from './Notification.module.scss';

const notificationIconLookup = {
  info: Info,
  warning: Info,
  general: Info,
  special: Info,
  error: TriangleAlert,
  success: Check,
};

const Notification = ({
  id,
  type = 'info',
  href = '',
  title = '',
  message,
  cta = '',
  variant = 'extended',
  dismissBehavior,
  environment = 'prod',
  sessionDismissDuration = Date.now() + 1000 * 60 * 30,
  onDismiss,
  onToggle,
  children,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [active, setActive] = useState(true);

  const NotificationIcon = notificationIconLookup[type];

  const handleClick = () => {
    if (variant === 'extended') {
      setDropdownOpen(!dropdownOpen);
      onToggle?.(!dropdownOpen);
    }
    if (variant === 'cta' && href) {
      window.location.href = href;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (variant === 'extended') {
        handleClick();
        event.stopPropagation();
        event.preventDefault();
      }
      if (variant === 'cta') handleClick();
    }
  };

  const handleDismiss = () => {
    if (dismissBehavior === 'permanent') {
      localStorage.setItem(id, JSON.stringify({ id, title, type, href, variant, dismissBehavior, cta, message }));
    } else if (dismissBehavior === 'session') {
      localStorage.setItem(
        id,
        JSON.stringify({
          id,
          title,
          type,
          href,
          variant,
          dismissBehavior,
          cta,
          message,
          expires: sessionDismissDuration,
        }),
      );
    }

    onDismiss?.(id);
    setActive(false);
  };

  const handleDismissKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleDismiss();
    }
  };

  if (!active) return null;

  return (
    <div
      className={`${styles.notification__wrapper} ${styles[type]} ${styles[variant]} ${
        dropdownOpen ? styles.open : ''
      }`}
    >
      <div
        className={styles['notification-bar']}
        onClick={handleClick}
        onKeyDown={handleKeyPress}
        tabIndex={variant === 'extended' ? 0 : -1}
        role="button"
      >
        <div className={styles['notification-bar__content']}>
          <div className={styles.heading}>
            <div className={styles.icon}>
              <NotificationIcon />
            </div>
            <h5>
              {title}
              {children?.['notification-title']}
            </h5>
            <div className={`${styles['toggle-icon']} ${variant !== 'extended' ? styles['d-none'] : ''}`}>
              <ChevronDown />
            </div>
            <a className={`${styles.cta__message} ${variant !== 'cta' ? styles['d-none'] : ''}`} id={id} href={href}>
              <p>
                <RichTextMessage message={message} environment={environment} />
                {children?.['cta-message']}
              </p>
              <span className={styles.link}>
                {children?.['cta-link-text']}
                {cta}
              </span>
            </a>
          </div>
          <button
            className={styles.notification__dismiss}
            onClick={(e) => {
              e.stopPropagation();
              handleDismiss();
            }}
            onKeyDown={handleDismissKeyPress}
            aria-label="Dismiss Notification"
          >
            <X />
          </button>
        </div>
      </div>
      <div
        className={`${styles.dropdown__content} ${variant !== 'extended' ? styles['d-none'] : ''}`}
        id={`${id}_content`}
        role="region"
      >
        <div id={`${id}_content-message`}>
          {children?.['dropdown-content']}
          <RichTextMessage message={message} environment={environment} />
        </div>
      </div>
    </div>
  );
};

export default Notification;

export const DOMAIN_MAP = {
  dev: 'devinvh.com',
  qa: 'qainvh.com',
  uat: 'uatinvh.com',
};

export const parseURL = (urlString, environment) => {
  if (!environment || environment === 'prod') {
    return urlString;
  }
  try {
    const url = new URL(urlString);
    if (url.host.includes('invitationhomes.com')) {
      const parsedURL = `${url.protocol}//www.${DOMAIN_MAP[environment]}${url.pathname}`;
      return parsedURL;
    }
    return urlString;
  } catch (err) {
    return urlString;
  }
};

import React, { useEffect, useState } from 'react';

// Styled UI
import { withConfig } from '@invitation-homes/styled-ui/lib/providers/Config';
import makeTheme from '@invitation-homes/styled-ui-theme-ih';
import { ThemeProvider } from '@material-ui/core/styles';

// Material UI
import { BaseLayout } from '@invitation-homes/styled-ui/lib/layouts/Base';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Notification from '../components/Notification/Notification';
import axios from 'axios';
import { mapContentfulProfileNavigation } from '../../utils/parseDocuments';
import styles from './SelfShowing.module.scss';
import { filterNotifications } from '../utils/filterNotifications';

const environment = process.env.REACT_APP_INVH_ENVIRONMENT;
const theme = makeTheme();

const SelfShowing = ({ children }) => {
  const [navigationHeader, setNavigationHeader] = useState([]);
  const [profileMenu, setProfileMenu] = useState([]);
  const [footerNavigation, setFooterNavigation] = useState([]);
  const [notifications, setNotifications] = useState([]);

  function getCookie(key) {
    var b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
  }

  function handleDismissNotification(notificationId) {
    this.setState((prevState) => {
      const dismissedNotifications = new Set(prevState.dismissedNotifications);
      dismissedNotifications.add(notificationId);

      const notification = prevState.notifications.find((n) => n.props.id === notificationId);
      if (notification?.props.dismissBehavior === 'session') {
        sessionStorage.setItem(`notification-${notificationId}`, 'dismissed');
      }

      return { dismissedNotifications };
    });
  }

  function getNotification(notifications) {
    const notification = filterNotifications(notifications, '', 'Self-show');

    if (!notification) return null;

    return (
      <Notification
        id={notification.id}
        type={notification.type}
        title={notification.title}
        message={notification.message}
        environment={environment}
        dismissBehavior={notification.dismissBehavior}
        variant={notification.variant}
        icon={notification.icon}
        onDismiss={() => handleDismissNotification(notification.id)}
      />
    );
  }

  useEffect(() => {
    let shouldUpdate = true;
    axios.get(`/api/v1/contentful`).then((resp) => {
      const { header, profile, footer, notifications } = resp.data;
      if (shouldUpdate) {
        setNavigationHeader(header);
        setProfileMenu(mapContentfulProfileNavigation(profile?.fields.navigationItems, true));
        setFooterNavigation(footer);
        setNotifications(notifications);
      }
    });
    return () => {
      shouldUpdate = false;
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {getNotification(notifications)}
      <Header navigation={navigationHeader} profileMenus={profileMenu} isLoggedIn={getCookie('accessToken')} />
      <BaseLayout>
        <div className={styles.container}>{children}</div>
      </BaseLayout>
      <Footer navigation={footerNavigation} collapsed={false} />
    </ThemeProvider>
  );
};

export default withConfig(SelfShowing);

import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { Label, Value, Disabled } from '../components/CardCommon';
import Button from '@material-ui/core/Button';
import { height } from 'styled-system';

function DayButton({ date, onClick = undefined, active = false, disabled = false, timezone, ...rest }) {
  let label;
  if (moment().tz(timezone).startOf('day').isSame(date.tz(timezone).startOf('day'))) {
    label = 'Today';
  } else if (moment().tz(timezone).add(1, 'd').startOf('day').isSame(date.tz(timezone).startOf('day'))) {
    label = 'Tomorrow';
  } else if (date.tz(timezone).isAfter(moment().tz(timezone).endOf('week'))) {
    label = 'Next Week';
  } else if (
    date.tz(timezone).isAfter(moment().tz(timezone).startOf('week')) &&
    date.tz(timezone).isBefore(moment().tz(timezone).endOf('week'))
  ) {
    label = 'This Week';
  }
  return (
    <Button
      p={0}
      color={active ? 'primary' : 'default'}
      style={{
        height: '100%',
      }}
      variant="contained"
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      fullWidth
      {...rest}
    >
      <Value
        padding="10px 0"
        margin="0"
        onClick={!disabled ? onClick : undefined}
        style={{ color: active && 'white', fontWeight: 'bold' }}
      >
        <Label color={active ? 'white' : 'default'}>{label || ''}</Label>
        {date.tz(timezone).format('ddd MMM Do')}
      </Value>
      {disabled && (
        <Disabled>
          <span>{typeof disabled === 'string' ? disabled : 'Reserved'}</span>
        </Disabled>
      )}
    </Button>
  );
}

DayButton.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default DayButton;

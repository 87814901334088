import React from 'react';

const X = () => (
  <svg width="100%" height="100%" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5893 4.41073C15.9147 4.73617 15.9147 5.26381 15.5893 5.58925L5.58925 15.5892C5.26382 15.9147 4.73618 15.9147 4.41074 15.5892C4.0853 15.2638 4.0853 14.7362 4.41074 14.4107L14.4107 4.41073C14.7362 4.0853 15.2638 4.0853 15.5893 4.41073Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41074 4.41073C4.73618 4.0853 5.26382 4.0853 5.58925 4.41073L15.5893 14.4107C15.9147 14.7362 15.9147 15.2638 15.5893 15.5892C15.2638 15.9147 14.7362 15.9147 14.4107 15.5892L4.41074 5.58925C4.0853 5.26381 4.0853 4.73617 4.41074 4.41073Z"
      fill="currentColor"
    />
  </svg>
);

export default X;

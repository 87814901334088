import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { isEqual } from 'lodash';
import { datadogRum } from '@datadog/browser-rum';

import Showing from './routes/Showing';
import ShowingEdit from './routes/ShowingEdit';
import ScheduleShowing from './routes/ScheduleShowing';

// Styled UI
import Provider from '@invitation-homes/styled-ui/lib/providers/Provider';
import EmitterRedirect from '@invitation-homes/styled-ui/lib/components/Router/EmitterRedirect';

// services
import { services, emitter } from './services';

// config
import { FEATURE_FLAGS, datadogRumConfig } from './config';
import ProfileDashboardRedirect from './components/ProfileDashboardRedirect';

class App extends Component {
  state = {
    config: {
      featureFlags: FEATURE_FLAGS,
    },
    user: null,
    notifications: [],
    dismissedNotifications: new Set(),
  };

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      datadogRum.init(datadogRumConfig);
    }
    emitter.on('auth', this.handleUserUpdate);
    services.auth.grant();
  }

  handleUserUpdate = (user) => {
    if (!isEqual(user, this.state.user)) {
      this.setState({ user });
    }
  };

  render() {
    const { user, config } = this.state;

    return (
      <Provider emitter={emitter} services={services} user={user} config={config}>
        <Router>
          <EmitterRedirect />
          <Switch>
            <Route exact path="/" component={ProfileDashboardRedirect} />
            <Route path="/showings" component={ProfileDashboardRedirect} />
            <Route path="/homes" component={ProfileDashboardRedirect} />
            <Route path="/showing/:id" render={({ match }) => <Redirect to={`/home/${match.params.id}`} />} />
            <Route
              path="/schedule-showing/:unit_id"
              render={({ location }) => {
                return <ScheduleShowing location={location} />;
              }}
            />
            <Route exact path="/home/:id" component={Showing} />
            <Route exact path="/home/:id/edit" component={ShowingEdit} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
